import { Col, Form, InputNumber, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { useEffect } from 'react';
import Title from 'antd/lib/typography/Title';
import {
  customFormatterForNumberInput,
  customParserForNumberInput
} from '../helpers/helpersNumberInput';

interface MinMaxNumberInputProps {
  minLimit: number;
  maxLimit: number;
  minTitle?: string;
  maxTitle?: string;
  setMinLimit: (minLimit: number) => void;
  setMaxLimit: (maxLimit: number) => void;
  title?: string;
  span?: number;
  maximum?: number;
  form?: FormInstance;
  formatter?: (value: number | string | undefined) => string;
}

const MinMaxNumberInput = ({
  maxLimit,
  minLimit,
  maxTitle,
  minTitle,
  setMaxLimit,
  setMinLimit,
  title,
  span,
  maximum,
  form,
  formatter
}: MinMaxNumberInputProps) => {
  const [defaultForm] = Form.useForm();

  useEffect(() => {
    (form || defaultForm).validateFields(['textLength']);
  }, [minLimit, maxLimit]);

  return (
    <Form name="min-max-number-input" form={form || defaultForm}>
      <Form.Item
        rules={[
          {
            validator: (_: any, val: any) => {
              if (minLimit > maxLimit) {
                return Promise.reject(new Error('Max sollte immer größer als Min sein.'));
              }
              return Promise.resolve();
            }
          }
        ]}
        name="textLength"
        className="remove-margin-bottom flex-column"
        extra={
          <div className="d-flex">
            <div className="d-flex w-100">{minTitle || 'Min'}</div>
            <div className="d-flex w-100" style={{ marginLeft: '20px' }}>
              {maxTitle || 'Max'}
            </div>
          </div>
        }
      >
        <>
          <Title className="settings-title" level={4}>
            {title}
          </Title>
          <Row className="text-limits" gutter={[20, 0]}>
            <Col span={span ? span / 2 : 12}>
              <InputNumber
                onChange={e => {
                  setMinLimit(e as number);
                }}
                formatter={value => customFormatterForNumberInput(value, formatter)}
                parser={value => customParserForNumberInput(value, formatter)}
                value={minLimit}
                title={minTitle || 'Min'}
                name="min"
              />
            </Col>
            <Col span={span ? span / 2 : 12}>
              <InputNumber
                onChange={e => {
                  setMaxLimit(e as number);
                }}
                formatter={value => customFormatterForNumberInput(value, formatter)}
                parser={value => customParserForNumberInput(value, formatter)}
                value={maxLimit}
                title="Max"
                name="max"
              />
            </Col>
          </Row>
        </>
      </Form.Item>
    </Form>
  );
};

export default MinMaxNumberInput;
