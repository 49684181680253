import React from 'react';
import { useDispatch } from 'react-redux';
import { useCurrentlyEditingBuilderTheme } from '../../Builder/hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import { DesignSettingsTabView, FunnelThemeType } from '../../Builder/interfaces/builderSliceTypes';
import { setCurrentlyEditingTheme, setDesignSettingsTabView, setIsEditingColorTheme } from '../../Builder/redux/builderSlice';

interface CircularIconProps {
  size?: string;
  className?: string;
  color?: 'defaultGradient' | 'primaryGradient' | 'secondaryGradient' | 'tertiaryGradient' | 'quaternaryGradient';
}

const CircularIcon: React.FC<CircularIconProps> = ({
  size = "23px",
  className = "",
  color = "defaultGradient"
}) => {
  const theme = useCurrentlyEditingBuilderTheme();
  const dispatch = useDispatch()
  const backgroundColor = theme && 'defaultGradient' in theme && color in theme
    ? (theme[color as keyof FunnelThemeType] as string)
    : "#000";

  const handleClick = (e :React.MouseEvent) => {
    dispatch(setDesignSettingsTabView(DesignSettingsTabView.COLORS))
    dispatch(setCurrentlyEditingTheme(theme as FunnelThemeType));
    dispatch(setIsEditingColorTheme(true));
  }

  return (
    <div
      className={className}
      onClick={handleClick}
      style={{
        borderRadius: "100%",
        background: backgroundColor,
        width: size,
        height: size
      }}
    />
  );
};

export default CircularIcon;
