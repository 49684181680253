import React from 'react';
import { SettingsComponents, SettingsElement } from '../Settings/settingsGbTypes';
import { FaTextHeight } from 'react-icons/fa';
import { BiBorderRadius } from 'react-icons/bi';
import { MdGradient } from 'react-icons/md';
import { ButtonAvailableBorderRadius } from '../../interfaces/ButtonSizeInterface';
import ChoiceTemplatesForPresets from '../elements/SingleChoiceComponent/components/ChoiceTemplatesForPresets';
import { RouteToGradient } from './RouteToGradient';
import CircularIcon from '../../../SharedUI/components/CircularIcon';

const getKeyValueObjectByKeyValueArrayOfObjects = (sizes: any[]): { [key: number]: string } =>
  sizes.reduce((acc, { value, key }) => {
    acc[key] = value;
    return acc;
  }, {});

export const ButtonPresetsSettingsItems: SettingsElement[] = [
  {
    component: SettingsComponents.SLIDER,
    block: false,
    disabled: false,
    title: 'Schriftgröße',
    key: 'size',
    icon: <FaTextHeight />,
    otherProps: {
      marks: {
        0: 'S',
        50: 'M',
        100: 'L'
      },
      step: 50,
      tooltipVisible: false,
      defaultValue: 50
    }
  },
  {
    component: SettingsComponents.SLIDER,
    block: false,
    disabled: false,
    title: 'Randradius',
    key: 'borderRadius',
    icon: <BiBorderRadius />,
    otherProps: {
      marks: getKeyValueObjectByKeyValueArrayOfObjects(ButtonAvailableBorderRadius),
      tooltipVisible: false,
      min: 1,
      max: 3,
      defaultValue: 3
    }
  },
  {
    component: SettingsComponents.FONT_STYLE_BUTTONS,
    block: true,
    disabled: false,
    title: 'Style',
    key: 'boldText',
    otherProps: {
      buttonsToRender: ['BOLD']
    }
  },
  {
    component: SettingsComponents.SWITCH,
    block: false,
    disabled: false,
    key: 'isDefaultGradient',
    shouldNotDisableWhenPresetIsOn: true,
    otherProps: {
      title: 'Verlauf',
      description: '',
      icon: <CircularIcon color="defaultGradient" />
    }
  },
];

export const ImagePresetsSettingsItems: SettingsElement[] = [
  {
    component: SettingsComponents.SLIDER,
    block: false,
    disabled: false,
    title: 'Randradius',
    key: 'borderRadius',
    icon: <BiBorderRadius />,
    otherProps: {
      marks: getKeyValueObjectByKeyValueArrayOfObjects(ButtonAvailableBorderRadius),
      tooltipVisible: false,
      min: 1,
      max: 3
    }
  }
];

export const ImageCarousalPresetsSettingsItems: SettingsElement[] = [
  {
    component: SettingsComponents.SLIDER,
    block: false,
    disabled: false,
    title: 'Randradius',
    key: 'borderRadius',
    icon: <BiBorderRadius />,
    otherProps: {
      marks: getKeyValueObjectByKeyValueArrayOfObjects(ButtonAvailableBorderRadius),
      tooltipVisible: false,
      min: 1,
      max: 3
    }
  }
];

export const ChoiceComponentPresetsSettingsItems: SettingsElement[] = [
  {
    component: SettingsComponents.CUSTOM_COMPONENT,
    block: false,
    disabled: false,
    title: 'Ausrichtung',
    key: 'align',
    customComponent: ChoiceTemplatesForPresets
  },
  {
    component: SettingsComponents.SLIDER,
    block: false,
    disabled: false,
    title: 'Randradius',
    key: 'borderRadius',
    icon: <BiBorderRadius />,
    otherProps: {
      marks: getKeyValueObjectByKeyValueArrayOfObjects(ButtonAvailableBorderRadius),
      tooltipVisible: false,
      min: 1,
      max: 3
    }
  },
  {
    component: SettingsComponents.SWITCH,
    block: false,
    disabled: false,
    key: 'isDefaultGradient',
    shouldNotDisableWhenPresetIsOn: true,
    otherProps: {
      title: 'Verlauf',
      description: '',
      icon: <CircularIcon color="defaultGradient" />
    }
  },
];
