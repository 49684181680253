import { formatNumber } from '../../Builder/AdJobBuilder/helper/validateFunnelJobPresets';

export const customFormatterForNumberInput = (
  value: number | string | undefined,
  formatter?: (value: number | string | undefined) => string
) => {
  if (formatter) {
    return formatter(value);
  } else {
    return formatNumber(`${value || ''}`);
  }
};

export const customParserForNumberInput = (
  value: string | undefined,
  formatter?: (value: number | string | undefined) => string
) => {
  if (formatter) {
    const formattedStringValue = formatter?.(value).toString();
    return parseFloat(formattedStringValue!.replace(/\./g, '').replace(',', '.'));
  } else {
    return parseFloat(value!.replace(/\./g, '').replace(',', '.'));
  }
};
